<template>
    <div class="row mb-5 dashboard">
        <div class="col-12">
            <div class="row mb-4 gy-3">
                <div class="col-md-12">
                    <h5 class="text-primary">Hi, {{activeUser.first_name}}</h5>
                </div>
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-sm-6">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri ri-file-text-line align-bottom"></i> Total Members</h5>
                            <p> {{result.group.members_count}} total members in your group</p>
                            <a class="text-decoration-underline" target="_blank"
                            :href="absoluteUrl(registerMemberLink)"> Add Member </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri  ri-account-circle-line align-bottom"></i> Your Account</h5>
                            <p>Information & setttings</p>
                            <router-link class="text-decoration-underline" to="/account"> Account settings </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isLoading" >
                <is-loading />
            </div>
            <div v-else class="row gy-3">
                <div class="col-md-7 align-self-center">
                    <div v-if="result.group.id" class="card overflow-hidden">
                    <div class="card-header">
                       <h5 class="mb-0">Group Details</h5>
                    </div>
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-start mb-3">
                            <div class="me-3">
                                <img @click="showProfileImg(result.group.logo)" class="cursor-pointer"
                                 v-if="result.group.logo" style="width:3rem;" :src="absoluteUrl(result.group.logo)" />
                            </div> 
                            <div class="">
                            <div class="friendly-id mb-1">
                                Code: <strong class="text-primary">{{result.group.friendly_id}}</strong>
                            </div>
                            <div class="">
                                <strong class="me-1">Status:</strong>
                                <span v-html="statusBadge(result.group.status)"> </span>
                            </div>
                            </div>
                        </div>
                        <h4 class="lh-base mb-4 mb-0">{{result.group.name}}</h4>
            
                        <h5 class="mb-2">How to add new Member:</h5>
                        <p>You can share the above code for your member's registration. Or you can share this link below:</p>
                        <a class="btn-link" target="_black" :href="absoluteUrl(registerMemberLink)">{{absoluteUrl(registerMemberLink)}}</a>
                    </div>
                </div>
                </div>
                <div class="col-md-5 align-self-center">
                    <h4 class="mb-4">Your ID Card</h4>
                    <div style="border-radius:2rem;" class="card text-center">
                        <img @click="showProfileImg(result.user.id_card)" :src="absoluteUrl(result.user.id_card)" class="cursor-pointer card-img-top" alt="Group head Id">
                    </div>
                    <div class="d-grid gap-3 d-sm-block"> 
                        <button @click.prevent="regenerateIdCard()" class="btn btn-dark me-sm-3" type="button">Regenerate ID</button>
                        <a :href="absoluteUrl(`/download?file_path=${result.user.id_card}`)" class="btn btn-primary" target="_blank">Download for printing</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
    name: 'dashboard',
    components:{
        UserEmailVerification,
        IsLoading,
    },
    data(){
        return{
            isLoading: true,
        }
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser;
      },
      result(){
        return this.$store.state.dashboardData
      },
      registerMemberLink(){
        return this.result.group ? `/register/member/${this.result.group.friendly_id}` : '...'
      }
    },
    methods:{
        regenerateIdCard(){
            this.isLoading = true;
            this.$http.get('/generate-id-card')
                .then((response) => {
                    this.isLoading = false;
                    if(response.data.success){
                        this.fetchDashboardData()
                    }
            })
        },
        fetchDashboardData(){
            this.isLoading = true
            this.$http.get('/analytics')
            .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.$store.commit('UPDATE_DASHBOARD', response.data.data)
                }
            })
        },
    },
    created(){
      this.fetchDashboardData()
    }
}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.1rem 0.2rem;
}

</style>
